import React, { useState } from "react";
import { ReactComponent as LanguageIcon } from "./assets/svg/LanguageIcon.svg";
import { ReactComponent as DropDownArrow } from "./assets/svg/DropdownArrow.svg";
import { ReactComponent as Circle } from "./assets/svg/LanguageCircle.svg";
import { ReactComponent as Car } from "./assets/svg/Car.svg";
import { ReactComponent as Music } from "./assets/svg/Music.svg";
import LinkCircle from "./assets/img/LinkCircle.png";
import { PC } from "hook/useMediaQueryHook";
import { useMediaQuery } from "react-responsive";
import { lang } from "util/locale/lang";

const MAIN_VIDEO =
  "https://indj.s3.ap-northeast-2.amazonaws.com/main/video/main.mp4";
const STREAMING_VIDEO =
  "https://indj.s3.ap-northeast-2.amazonaws.com/main/video/streaming.mp4";
const CAR_VIDEO =
  "https://indj.s3.ap-northeast-2.amazonaws.com/main/video/connectedcar.mp4";
const VOUCHER_URL =
  "https://indj.s3.ap-northeast-2.amazonaws.com/main/main_voucher.png";
const PageBox = ({
  box,
  global,
  url,
}: {
  box: "streaming" | "car";
  global: "ko" | "en";
  url: string;
}) => {
  const pageMove = () => {
    window.open(url, "_blank");
  };
  const isMobile = useMediaQuery({ query: "(max-width:1099px)" });
  return (
    <div
      className={`relative w-full flex flex-col  ${
        isMobile
          ? "after:content-[''] after:pb-[100%] after:block"
          : "cursor-pointer"
      }`}
      onClick={pageMove}
    >
      <video
        muted
        src={box === "car" ? CAR_VIDEO : STREAMING_VIDEO}
        loop
        autoPlay
        playsInline
        className={`rounded-2xl object-cover ${
          isMobile ? "absolute w-full h-full" : "w-full h-246"
        }  `}
      ></video>
      <h2
        className={`absolute font-bold ${
          isMobile
            ? "top-16 left-16 text-20 leading-[160%] "
            : "top-24 left-24 text-30"
        }`}
      >
        {box !== "car" ? lang.lang30[global] : lang.lang50[global]}
      </h2>

      <p
        className={`absolute leading-[160%] whitespace-pre-wrap  ${
          isMobile
            ? global === "ko"
              ? "w-141 top-52 left-16"
              : "top-52 left-16"
            : "top-70 left-24"
        } `}
      >
        {box !== "car" ? lang.lang40[global] : lang.lang60[global]}
      </p>
      <div
        className={`absolute ${
          isMobile
            ? "top-[74.193%] right-16"
            : "top-152 left-24 flex items-center gap-80"
        }`}
      >
        <img
          className={`${isMobile ? "h-32" : "h-48 self-end"}`}
          src={LinkCircle}
          alt="스트리밍페이지 이동버튼"
        />
        <PC>{box !== "car" ? <Music /> : <Car />}</PC>
      </div>
    </div>
  );
};
const App = () => {
  const [globalLang, setGlobalLang] = useState<"ko" | "en">("ko");
  const [langModal, setLangModal] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width:1099px)" });
  // 이미지 상수
  const INDJ_LOGO = `https://indj.s3.ap-northeast-2.amazonaws.com/intro/global/${
    isMobile ? "mobile" : "web"
  }_logo.png`;
  const AWSURL = `https://indj.s3.ap-northeast-2.amazonaws.com/main/${
    isMobile ? "mobile" : "pc"
  }_main_noonchee.png`;

  const onClickModal = (lang: "ko" | "en") => {
    setGlobalLang(lang);
    setLangModal(false);
  };
  const pageMove = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className={`App overflow-hidden ${isMobile ? "px-20 pb-46" : ""}`}>
      {/* 헤더 */}
      <header
        className={`relative flex justify-between ${
          isMobile ? "pt-56" : "w-[1612px] pt-56 mx-auto"
        }`}
      >
        <img
          className={`pointer ${isMobile ? `h-26` : `h-50`}`}
          src={INDJ_LOGO}
          alt="인디제이 로고"
        />
        {/* <div
          className={`
          flex gap-4 items-center [&>svg:nth-of-type(2)]:w-20 [&>svg:nth-of-type(2)]:h-20} pointer
          ${isMobile ? `` : ``}`}
          onClick={() => setLangModal((el) => !el)}
        >
          <LanguageIcon />
          <DropDownArrow />
        </div> */}

        <ul
          className={`language_ul ${langModal ? "modal_on" : "modal_off"} ${
            isMobile ? "top-82" : "top-106"
          }`}
        >
          <li className="language_li" onClick={() => onClickModal("ko")}>
            {globalLang === "ko" && <Circle />}
            한국어
          </li>

          <li className="language_li" onClick={() => onClickModal("en")}>
            {globalLang === "en" && <Circle />}
            English
          </li>
        </ul>
      </header>
      {/* 아이템  */}
      <div
        className={`flex ${
          isMobile ? "gap-42 mt-24 flex-col" : "w-[1612px] gap-24 mt-56 mx-auto"
        }`}
      >
        <div
          className={`relative cursor-pointer`}
          onClick={() => pageMove("https://corp.indj.ai/")}
        >
          <video
            muted
            src={MAIN_VIDEO}
            loop
            autoPlay
            playsInline
            className={`relative rounded-2xl object-cover ${
              isMobile ? "w-screen h-[47.692vw]" : "w-[1342px] h-[712px] "
            }  `}
          ></video>

          <img
            src={AWSURL}
            alt="눈치AI"
            className={` absolute ${
              isMobile
                ? "h-[13.846vw] top-[25%] left-[50%] -translate-y-1/4 -translate-x-1/2"
                : "h-156 top-56 left-56"
            }`}
          />
          <div
            className={`${
              isMobile ? "relative mt-16" : "absolute flex top-244 left-56"
            }`}
          >
            <p
              className={`text-20 ${
                isMobile
                  ? globalLang === "ko"
                    ? "leading-[160%] w-197"
                    : "leading-[160%]"
                  : "text-42 leading-[125%] whitespace-pre-wrap"
              }`}
            >
              {lang.lang10[globalLang]}
            </p>
          </div>
          <div
            className={`flex items-center justify-center ${
              isMobile
                ? "mt-16 rounded-[68px] gap-8 bg-[#494949]"
                : "absolute bottom-62 left-56 gap-16 cursor-pointer"
            }`}
          >
            <p
              className={`${
                isMobile ? "py-11 font-semibold leading-[160%]" : "text-30 "
              }`}
            >
              {lang.lang20[globalLang]}
            </p>
            <button className={`${isMobile ? "" : ""}`}>
              <img
                className={`${isMobile ? "h-24" : "h-48"}`}
                src={LinkCircle}
                alt="회사소개페이지 이동버튼"
              />
            </button>
          </div>
        </div>
        <div
          className={`relative flex ${
            isMobile ? "justify-between h-[99.487vw]" : "w-246 flex-col gap-24"
          }`}
        >
          <div
            className={`flex flex-col ${
              isMobile ? "gap-16 w-[53.142%]" : "gap-24"
            }`}
          >
            <PageBox
              box="streaming"
              global={globalLang}
              url={"https://player.indj.ai/"}
            />
            <PageBox
              box="car"
              global={globalLang}
              url={"https://car.indj.ai/"}
            />
          </div>
          <div
            className={`relative ${
              isMobile ? "w-[42.285%]" : "cursor-pointer h-176"
            }`}
            onClick={() => pageMove("https://corp.indj.ai/voucher")}
          >
            <img
              className="h-full object-cover rounded-2xl"
              src={VOUCHER_URL}
              alt="ai바우처이미지"
            />
            <h2
              className={`absolute font-bold ${
                isMobile
                  ? "top-16 left-16 text-20 leading-[160%]"
                  : "top-24 left-24 text-30"
              }`}
            >
              {lang.lang70[globalLang]}
            </h2>
            <p
              className={`absolute leading-[160%] ${
                isMobile
                  ? globalLang === "ko"
                    ? "top-52 left-16 w-70 leading-[160%]"
                    : "top-52 left-16 w-81 "
                  : "top-76 left-24 whitespace-pre-wrap"
              }`}
            >
              {lang.lang80[globalLang]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
