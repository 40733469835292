interface LangType {
  [key: string]: {
    ko: string;
    en: string;
  };
}
export const lang: LangType = {
  lang10: {
    ko: `상황・감정으로 연결하는 AI와 나의 연결고리`,
    en: `AI and Human Connection in 
Understanding Situations and Emotion`,
  },
  lang20: {
    ko: `회사소개 및 AI기술 소개`,
    en: `Company Introduction`,
  },
  lang30: {
    ko: `스트리밍`,
    en: `Streaming`,
  },
  lang40: {
    ko: `나보다 나를 더 잘 아는
AI 음악친구를
만나보세요`,
    en: `Meet AI music friends
who know me
better than I do`,
  },
  lang50: {
    ko: `커넥티드카`,
    en: `Connected car`,
  },
  lang60: {
    ko: `운전자와 자동차의
상황,감정 데이터 분석`,
    en: `Analyzes driver Situations
and emotional data`,
  },
  lang70: {
    ko: `바우처`,
    en: `Voucher`,
  },
  lang80: {
    ko: `AI 바우처 지원사업
바로가기`,
    en: `AI Voucher 
Support Program`,
  },
};
